<template>
    <div class="bg-gradient-primary">
        <div class="container p-4">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8 col-xl-7">
                    <div class="card shadow-lg o-hidden border-0 my-5">
                        <div class="card-body p-0" v-if="!successful">
                            <div class="row">
                                <div class="col">
                                    <div class="p-5">
                                        <div class="text-center">
                                            <h4 class="text-dark mb-4">
                                                Create an Account!
                                            </h4>
                                        </div>
                                        <ValidationObserver
                                            v-slot="{ handleSubmit }"
                                        >
                                            <form
                                                class="user"
                                                @submit.prevent="
                                                    handleSubmit(onSubmit)
                                                "
                                                novalidate
                                            >
                                                <div class="form-group row">
                                                    <div class="col">
                                                        <ValidationProvider
                                                            name="Username"
                                                            rules="required"
                                                            v-slot="{ errors }"
                                                        >
                                                            <input
                                                                class="form-control form-control-user"
                                                                type="text"
                                                                placeholder="Username"
                                                                name="username"
                                                                v-model="
                                                                    user.username
                                                                "
                                                            />
                                                            <span
                                                                class="small text-danger"
                                                            >
                                                                {{ errors[0] }}
                                                            </span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <ValidationProvider
                                                        name="E-mail"
                                                        rules="required|email"
                                                        v-slot="{ errors }"
                                                    >
                                                        <input
                                                            class="form-control form-control-user"
                                                            type="email"
                                                            placeholder="Email Address"
                                                            name="email"
                                                            v-model="user.email"
                                                        />
                                                        <span
                                                            class="small text-danger"
                                                        >
                                                            {{ errors[0] }}
                                                        </span>
                                                    </ValidationProvider>
                                                </div>
                                                <ValidationObserver>
                                                    <div class="form-group row">
                                                        <div
                                                            class="col-sm-6 mb-3 mb-sm-0"
                                                        >
                                                            <ValidationProvider
                                                                name="Password"
                                                                rules="required|min:6|password:@confirm"
                                                                v-slot="{
                                                                    errors,
                                                                }"
                                                            >
                                                                <input
                                                                    class="form-control form-control-user"
                                                                    type="password"
                                                                    placeholder="Password"
                                                                    v-model="
                                                                        user.password
                                                                    "
                                                                />
                                                                <span
                                                                    class="small text-danger"
                                                                >
                                                                    {{
                                                                        errors[0]
                                                                    }}
                                                                </span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <ValidationProvider
                                                                name="confirm"
                                                                rules="required"
                                                                v-slot="{
                                                                    errors,
                                                                }"
                                                            >
                                                                <input
                                                                    class="form-control form-control-user"
                                                                    type="password"
                                                                    placeholder="Repeat Password"
                                                                    v-model="
                                                                        user.passwordRepeat
                                                                    "
                                                                />
                                                                <span
                                                                    class="small text-danger"
                                                                >
                                                                    {{
                                                                        errors[0]
                                                                    }}
                                                                </span>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </ValidationObserver>
                                                <div class="form-group small">
                                                    <div class="form-check">
                                                        <ValidationProvider
                                                            name="privacy check"
                                                            :rules="{
                                                                required: {
                                                                    allowFalse: false,
                                                                },
                                                            }"
                                                            v-slot="{ errors }"
                                                        >
                                                            <input
                                                                class="form-check-input custom-control-input"
                                                                type="checkbox"
                                                                id="policyCheck"
                                                                v-model="
                                                                    user.agree
                                                                "
                                                            />
                                                            <label
                                                                class="form-check-label custom-control-label"
                                                                for="policyCheck"
                                                            >
                                                                I agree with the
                                                                <a
                                                                    data-toggle="policymodal"
                                                                    href="#policyModal"
                                                                    v-b-modal.policyModal
                                                                >
                                                                    Privacy
                                                                    Policy
                                                                </a>
                                                            </label>
                                                            <br />
                                                            <span
                                                                class="text-danger"
                                                            >
                                                                {{ errors[0] }}
                                                            </span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <button
                                                    class="btn btn-success btn-block text-white btn-user"
                                                    type="submit"
                                                    :disabled="loading"
                                                >
                                                    Register Account
                                                </button>
                                                <hr />
                                            </form>
                                        </ValidationObserver>
                                        <div class="text-center">
                                            <router-link
                                                to="/login"
                                                class="small"
                                            >
                                                Already have an account? Login!
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body text-center text-muted" v-else>
                            <p class="p-4">You've successfully registered!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="policyModal" class="modal fade" role="dialog" ok-only>
            <div class="modal-dialog">
                <h4 class="modal-title">Privacy Policy:</h4>
                <div class="modal-dialog">
                    <p>
                        When registering for the INSYSTED platform, you are free
                        to choose any username and email address. They do not
                        necessarily have to contain any personal information. If
                        you join a game, all other players will be able to see
                        your username, your email address will not be visible.
                        Right now, all user accounts as well as game templates
                        and games are erased every two weeks. We might adjust
                        that functionality in the future for users to save game
                        templates, please check our most recent information.
                    </p>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            user: {
                firstName: '',
                username: '',
                email: '',
                password: '',
                passwordRepeat: '',
                agree: false,
            },
            successful: false,
            loading: false,
        }
    },
    methods: {
        onSubmit() {
            this.loading = true

            this.$store
                .dispatch('register', {
                    name: `${this.user.username}`,
                    email: this.user.email,
                    password: this.user.password,
                })
                .then(
                    (data) => {
                        this.successful = true
                    },
                    (error) => {
                        this.loading = false
                        this.$bvToast.toast(error.response.data.message, {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            variant: 'danger',
                            solid: true,
                        })
                    }
                )
        },
    },
}
</script>
<style scoped>
body {
    background: #dfe7e9;
    font-family: 'Roboto', sans-serif;
}

.form-control {
    font-size: 16px;
}

.form-control,
.btn {
    border-radius: 50px;
    outline: none !important;
}
</style>
